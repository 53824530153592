import React from 'react'
import ResultCategory from '../ResultCategory'
import ResultImageRow from '../ResultImageRow/ResultImageRow'

import LackofFirmness from '../../../Assets/Result/icons/lack of firmness op2.png'
import Anitoxidant from '../../../Assets/Result/icons/anti - oxidant op2.png'

import Extract from '../../../Assets/Result/icons/shea butter op1.png'
import Protein from '../../../Assets/Result/icons/protein op2.png'
import Water from '../../../Assets/Result/icons/hydration op1.png'
import Sun from '../../../Assets/Result/icons/sunscreen op1.png'

import Sleep from '../../../Assets/Result/icons/sleep op2.png'
import Hydration from '../../../Assets/Result/icons/hydration op2.png'
import Massage from '../../../Assets/Result/icons/firm healthy skin op1.png'
import Diet from '../../../Assets/Result/icons/diet rich vegetables and fruits op1.png'

const DarkSpots = () => {
  return (
    <ResultCategory  
      concernContent={
        <ul>
          <li>
            <p>Dark Spots, which occur when certain areas of the skin produce more melanin than others.</p>
          </li>
          <ResultImageRow imgLink={LackofFirmness} >
            Common Causes:
            <ul>
              <li>Increased melanin production in specific areas of the skin.</li>
              <li>Exposure to harmful sun rays, including UV, infrared, and visible light.</li>
            </ul>
          </ResultImageRow>
          <ResultImageRow imgLink={Anitoxidant} >
            What you need:
            <ul>
              <li>A broad-spectrum sunscreen with an SPF of 30 or higher, which protects against the full range of sun rays—UV, infrared, and visible light. This helps to reduce the appearance of dark spots, even on overcast days or indoors.</li>
              <li>Ensure your sunscreen is lightweight for easy daily use, making it simple to incorporate into your skincare routine.</li>
            </ul>
          </ResultImageRow>
        </ul>
      }
      solutionContent={
        <ul>
          <li>
            <p>Zendermis Sun Shield Sunscreen SPF 50 Ultralight Sunscreen Gel is formulated for sun protection for all skin types. The broad spectrum sunscreen offers highly effective protection against UVA and UVB rays and prevents tanning, pigmentation and dark spots over skin due to prolonged exposure to sun rays . The Octocrylene, Avobenzone and Zinc Oxide combination helps to minimize the UV induced skin aging. This ultralight sunscreen is water and sweat resistant to give long lasting sun protection. The sunscreen does not leave any white cast and it is the perfect under make up for your zen like skin.</p>
          </li>
          <li>
            <p>This lightweight yet deeply hydrating cream:</p>
          </li>
          <ResultImageRow imgLink={Extract} >
            The broad spectrum sunscreen offers highly effective protection against UVA and UVB rays and prevents tanning, pigmentation and dark spots over skin due to prolonged exposure to sun rays.
          </ResultImageRow>
          <ResultImageRow imgLink={Protein} >
            The Octocrylene, Avobenzone and Zinc Oxide combination helps to minimize the UV induced skin aging.
          </ResultImageRow>
          <ResultImageRow imgLink={Water} >
            This ultralight sunscreen is water and sweat resistant to give long lasting sun protection.
          </ResultImageRow>
          <ResultImageRow imgLink={Sun} >
            The sunscreen does not leave any white cast and it is the perfect under make up for your zen like skin.
          </ResultImageRow>
        </ul>
      }
      expertTipContent={
        <ul>
          <li>
            <p>Dark spots can occur due to sun damage, hormonal changes, and other factors. To reduce and prevent dark spots:</p>
          </li>
          <ResultImageRow imgLink={Diet} >
            Protect your skin from intense sunlight by seeking shade and wearing sunscreen.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration} >
            Follow a vitamin-rich diet to support healthy skin and its natural defense mechanisms.
          </ResultImageRow>
          <ResultImageRow imgLink={Massage} >
            Incorporate broad-spectrum sunscreens and antioxidants into your skincare routine to protect your skin from UV damage and free radicals.
          </ResultImageRow>
        </ul>
      }
    />
  )
}

export default DarkSpots