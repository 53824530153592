import React from 'react'
import GuidePage from '../../GuidePage'
import page1 from '../../../../Assets/Guide/1-compressed.png'

const GuidePage1 = () => {
  return (
    <div>
      <GuidePage imgPath={page1}>
          <h1>Personlized Skincare Intelligence</h1>
          {/* <img
          src="https://smartskinadvisor.dermafique.com/analysis/assets/newassets/smartdermaconsult.png"
          alt="title"
          /> */}
          <p>
          Our AI analyzers your unique skin to provide personlized guidance and product recommendations for healthier skin.
          </p>
      </GuidePage>
    </div>
  )
}

export default GuidePage1