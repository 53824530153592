import axios from "axios"

const baseURL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: baseURL
})

export const authToken = async () => {
    await axiosInstance.post('/smartskin/authToken', 
        {
            "username" : "zendermis",
            "password" : "zendermis"
        },
        {
            headers: {
                "Accept" : "application/json"
            }
        }
    ).then((token) => localStorage.setItem("apikey", token.data.accessToken))
    .catch((error) => console.log(error))
}

export const productDetails = async (productSkusArray) => {
    return await axiosInstance.post('/smartskin/product-details', 
        {
            "skus" : productSkusArray
        },
        {
            headers: {
                'apiKey': localStorage.getItem("apikey"),
                'Content-Type': 'application/json'
            }
        }
    ).then((list) => list)
    .catch((error) => console.log(error))
}

export const sendOtp = async (mobile) => {
    return await axiosInstance.post('/smartskin/send-otp',
        {
            "mobile": mobile,
            "type" : "generate"
        },
        {
            headers: {
                'apiKey': localStorage.getItem("apikey"),
                'Content-Type': 'application/json'
            }
        }
    ).then((data) => data)
    .catch((error) => console.log(error))
}

export const verifyOtp = async (mobile, otp) => {
    let [gender, age, skin_type] = window.userData
    gender = (gender === 0) ? 'f' : 'm'
    skin_type = (skin_type === 0) ? 'oily' : (skin_type === 1) ? 'dry' : 'normal'

    return await axiosInstance.post('/smartskin/verify-otp',
        {
            "mobile" : mobile,
            "type" : "verify",
            "otp" : Number(otp),
            "gender" : gender,
            "age" : age,
            "skin_type" : skin_type
        },
        {
            headers: {
                'apiKey': localStorage.getItem("apikey"),
                'Content-Type': 'application/json'
            }
        }
    ).then((data) => data)
    .catch((error) => console.log(error))
}