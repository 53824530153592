import React from 'react'
import ResultCategory from '../ResultCategory'
import ResultImageRow from '../ResultImageRow/ResultImageRow'

import LackofFirmness from '../../../Assets/Result/icons/lack of firmness op2.png'
import Anitoxidant from '../../../Assets/Result/icons/anti - oxidant op2.png'

import Extract from '../../../Assets/Result/icons/shea butter op1.png'
import Protein from '../../../Assets/Result/icons/protein op2.png'
import Water from '../../../Assets/Result/icons/hydration op1.png'
import Sun from '../../../Assets/Result/icons/sunscreen op1.png'

import Sleep from '../../../Assets/Result/icons/sleep op2.png'
import Hydration from '../../../Assets/Result/icons/hydration op2.png'
import Massage from '../../../Assets/Result/icons/firm healthy skin op1.png'
import Diet from '../../../Assets/Result/icons/diet rich vegetables and fruits op1.png'

const Dullness = () => {
  return (
    <ResultCategory  
      concernContent={
        <ul>
          <li>
            <p>Dull Skin, which lacks radiance and appears lacks luster.</p>
          </li>
          <ResultImageRow imgLink={LackofFirmness} >
            Common Causes:
            <ul>
              <li>A buildup of dead skin cells on the surface.</li>
              <li>Reduced luminosity due to skin dryness and a slow cell turnover rate.</li>
              <li>Insufficient hydration, leading to a lack of vibrancy.</li>
            </ul>
          </ResultImageRow>
          <ResultImageRow imgLink={Anitoxidant} >
            What you need:
            <ul>
              <li>A hydrating skincare solution with ingredients like Vitamin E, an antioxidant that helps repair cellular damage and combat skin dullness.</li>
              <li>Skincare products containing seaweed extracts, which are known to brighten the skin and reduce the appearance of dullness, restoring a healthy glow.</li>
            </ul>
          </ResultImageRow>
        </ul>
      }
      solutionContent={
        <ul>
          <li>
            <p>Zendermis Deep Cleansing Face Wash with its unique formula manages Blemishes and Brightens Skin Tone.The unique formulation is added with Glutathione for skin lightening effects and salicylic acid that unclogs pores and sheds of dead skin cells. The Jojoa beeds in the formulation helps to exfoliate, cleanse and work on improving the complexion and skin tone. </p>
          </li>
          <li>
            <p>This lightweight yet deeply hydrating cream:</p>
          </li>
          <ResultImageRow imgLink={Extract} >
            Is infused with Sea Weed Extracts, that are known to contain vitamins & minerals which have hydrating & brightening properties.
          </ResultImageRow>
          <ResultImageRow imgLink={Protein} >
            Zendermis Deep Cleansing Face Wash with its unique formula manages Blemishes and Brightens Skin Tone.
          </ResultImageRow>
          <ResultImageRow imgLink={Water} >
            The unique formulation is added with Glutathione for skin lightening effects and salicylic acid that unclogs pores and sheds of dead skin cells.
          </ResultImageRow>
          <ResultImageRow imgLink={Sun} >
            The Jojoa beeds in the formulation helps to exfoliate, cleanse and work on improving the complexion and skin tone.
          </ResultImageRow>
        </ul>
      }
      expertTipContent={
        <ul>
          <li>
            <p>To Prevent and Reduce Dullness:</p>
          </li>
          <ResultImageRow imgLink={Diet} >
            Eat a nutrient-rich diet with antioxidants and vitamins by incorporating green leafy vegetables and nuts into your daily meals.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration} >
            Stay hydrated throughout the day to help enhance your skin&#39;s natural glow.
          </ResultImageRow>
          <ResultImageRow imgLink={Massage} >
            Practice regular facial massages, yoga, and exercises to boost blood circulation and promote a healthy complexion.
          </ResultImageRow>
          <ResultImageRow imgLink={Sleep} >
            Ensure you get adequate sleep, aiming for at least 7 hours each night to allow your skin to rejuvenate.
          </ResultImageRow>
        </ul>
      }
    />
  )
}

export default Dullness