import React from 'react'
import ResultCategory from '../ResultCategory'
import ResultImageRow from '../ResultImageRow/ResultImageRow'

import LackofFirmness from '../../../Assets/Result/icons/lack of firmness op2.png'
import Anitoxidant from '../../../Assets/Result/icons/anti - oxidant op2.png'

import Extract from '../../../Assets/Result/icons/shea butter op1.png'
import Protein from '../../../Assets/Result/icons/protein op2.png'
import Water from '../../../Assets/Result/icons/hydration op1.png'
import Sun from '../../../Assets/Result/icons/sunscreen op1.png'

import Sleep from '../../../Assets/Result/icons/sleep op2.png'
import Hydration from '../../../Assets/Result/icons/hydration op2.png'
import Massage from '../../../Assets/Result/icons/firm healthy skin op1.png'
import Diet from '../../../Assets/Result/icons/diet rich vegetables and fruits op1.png'

const UnevenSkinTone = () => {
  return (
    <ResultCategory  
      concernContent={
        <ul>
          <li>
            <p>Uneven skin tone occurs due to factors like pigmentation, sun damage, and skin inflammation.</p>
          </li>
          <ResultImageRow imgLink={LackofFirmness} >
            Common Causes:
            <ul>
              <li>Increased melanin production due to prolonged sun exposure.</li>
              <li>A weakened skin barrier from dehydration or lack of hydration.</li>
              <li>Factors like stress, pollution, aging, and dehydration can also contribute to uneven skin tone.</li>
            </ul>
          </ResultImageRow>
          <ResultImageRow imgLink={Anitoxidant} >
            What you need:
            <ul>
              <li>Skincare products with active ingredients such as Vitamin B3 to brighten the skin and diminish dark spots.</li>
              <li>Look for formulations with plant extracts that hydrate the skin and help even out tone over time.</li>
            </ul>
          </ResultImageRow>
        </ul>
      }
      solutionContent={
        <ul>
          <li>
            <p>Alpha Arbutin reduces the degree of skin darkening caused by sun exposure or acne inflammation by blocking the production of tyrosinase. It is also less irritating than other skin-lightening agents and is totally compatible with other skincare products like Vitamin C. Reduces dark spots, acne marks (or pimple marks), resulting in even skin tone. A regular use of this serum can help in reducing skin tan, dark spots & photo damage along with broad spectrum sunscreen. It inhibits melanin production without causing cell cytotoxicity thus is safe to be used as anti-pigmentation daily serum for longer durations unlike other skin lightening or brightening ingredients.</p>
          </li>
          <li>
            <p>This lightweight yet deeply hydrating cream:</p>
          </li>
          <ResultImageRow imgLink={Extract} >
            It is also less irritating than other skin-lightening agents and is totally compatible with other skincare products like Vitamin C.
          </ResultImageRow>
          <ResultImageRow imgLink={Protein} >
            Reduces dark spots, acne marks (or pimple marks), resulting in even skin tone.
          </ResultImageRow>
          <ResultImageRow imgLink={Water} >
            A regular use of this serum can help in reducing skin tan, dark spots & photo damage along with broad spectrum sunscreen.
          </ResultImageRow>
          <ResultImageRow imgLink={Sun} >
            It inhibits melanin production without causing cell cytotoxicity thus is safe to be used as anti-pigmentation daily serum for longer durations unlike other skin lightening or brightening ingredients.
          </ResultImageRow>
        </ul>
      }
      expertTipContent={
        <ul>
          <li>
            <p>Hormonal imbalances can also play a role in uneven skin tone. To help combat uneven skin tone:</p>
          </li>
          <ResultImageRow imgLink={Diet} >
            Adopt healthy lifestyle habits by maintaining a diet rich in antioxidants and staying properly hydrated throughout the day.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration} >
            Limit sun exposure and apply sunscreen regularly to protect your skin.
          </ResultImageRow>
          <ResultImageRow imgLink={Massage} >
            If you experience irregular periods or accompanying hair loss, it's advisable to consult with a dermatologist or gynecologist for further evaluation.
          </ResultImageRow>
        </ul>
      }
    />
  )
}

export default UnevenSkinTone