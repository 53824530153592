import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import QuestionPage from "../QuestionPage";
import Normal from '../../../Assets/Question/op2.png'
import Oily from '../../../Assets/Question/op2(1).png'
import Dry from '../../../Assets/Question/op2(2).png'

const QuestionPage3 = ({ setData }) => {
  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    className: 'center',
    centerPadding: (window.innerWidth <= "420") ? "95px" : "120px",
    afterChange: (index) => {
      setData(prevData => {
        const newData = [...prevData]
        newData[2] = index
        return newData
      })
    },
  };

  return (
    <QuestionPage
      title="How does your skin feel after cleansing?"
      description="Did you know the signs of ageing begin to show as early as the age of 18"
    >
      <Slider {...settings}>
        <div className="skin-type-option">
          <img
            src={Oily}
            alt="oily"
          />
        </div>
        <div className="skin-type-option">
          <img
            src={Dry}
            alt="dry"
          />
        </div>
        <div className="skin-type-option">
          <img
            src={Normal}
            alt="normal"
          />
        </div>
      </Slider>
    </QuestionPage>
  );
};

export default QuestionPage3;
