import React from 'react'
import ResultCategory from '../ResultCategory'
import ResultImageRow from '../ResultImageRow/ResultImageRow'

import LackofFirmness from '../../../Assets/Result/icons/lack of firmness op2.png'
import Anitoxidant from '../../../Assets/Result/icons/anti - oxidant op2.png'

import Extract from '../../../Assets/Result/icons/shea butter op1.png'
import Protein from '../../../Assets/Result/icons/protein op2.png'
import Water from '../../../Assets/Result/icons/hydration op1.png'
import Sun from '../../../Assets/Result/icons/sunscreen op1.png'

import Sleep from '../../../Assets/Result/icons/sleep op2.png'
import Hydration from '../../../Assets/Result/icons/hydration op2.png'
import Massage from '../../../Assets/Result/icons/firm healthy skin op1.png'
import Diet from '../../../Assets/Result/icons/diet rich vegetables and fruits op1.png'

const Firmness = () => {
  return (
    <ResultCategory  
      concernContent={
        <ul>
          <li>
            <p>Lack of Firmness, indicating a loss of elasticity and a need for hydration to maintain skin suppleness.</p>
          </li>
          <ResultImageRow imgLink={LackofFirmness} >
            Common Causes:
            <ul>
              <li>Reduced firmness is a natural sign of aging. As we age, skin cell regeneration slows down, and the production of collagen and elastin decreases.</li>
              <li>Loss of moisture can cause the skin to feel less plump and less firm.</li>
            </ul>
          </ResultImageRow>
          <ResultImageRow imgLink={Anitoxidant} >
            What you need:
            <ul>
              <li>A skincare solution containing plant stem cell extracts, antioxidants, and humectants, which are known to help regenerate skin cells, hydrate the skin, and improve firmness.</li>
            </ul>
          </ResultImageRow>
        </ul>
      }
      solutionContent={
        <ul>
          <li>
            <p>Zendermis Skin Hydration Face Serum is formulated for intense hydration and instant plump looking skin. The unique low molecular weight hyaluronic acid penetrates into the deep dermal layer of the skin to provide optimum hydration and reduce the appearance of fine lines and wrinkles.</p>
          </li>
          <li>
            <p>This lightweight yet deeply hydrating cream:</p>
          </li>
          <ResultImageRow imgLink={Extract} >
            Is infused with Sea Weed Extracts, that are known to contain vitamins & minerals which have hydrating & brightening properties.
          </ResultImageRow>
          <ResultImageRow imgLink={Protein} >
            Contains antioxidants like Vitamin E and Vitamin B5 that are known to help repair skin damage and reduce skin dullness.
          </ResultImageRow>
          <ResultImageRow imgLink={Water} >
            Zendermis Skin Hydration Face Serum is formulated for intense hydration and instant plump looking skin.
          </ResultImageRow>
          <ResultImageRow imgLink={Sun} >
            The unique low molecular weight hyaluronic acid penetrates into the deep dermal layer of the skin to provide optimum hydration and reduce the appearance of fine lines and wrinkles.
          </ResultImageRow>
        </ul>
      }
      expertTipContent={
        <ul>
          <li>
            <p>For Firm, Healthy Skin:</p>
          </li>
          <ResultImageRow imgLink={Diet} >
            Eat a diet rich in green, red, and orange fruits and vegetables to provide a variety of antioxidants that support skin health.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration} >
            Consume adequate protein to help repair the skin and delay the aging process.
          </ResultImageRow>
          <ResultImageRow imgLink={Massage} >
            Get at least 7 hours of sleep each night to support skin regeneration and overall health.
          </ResultImageRow>
          <ResultImageRow imgLink={Sleep} >
            Protect your skin with sunscreen to shield it from the harmful effects of UV rays.
          </ResultImageRow>
        </ul>
      }
    />
  )
}

export default Firmness