import React from 'react'
import ResultCategory from '../ResultCategory'
import ResultImageRow from '../ResultImageRow/ResultImageRow'

import LackofFirmness from '../../../Assets/Result/icons/lack of firmness op2.png'
import Anitoxidant from '../../../Assets/Result/icons/anti - oxidant op2.png'

import Extract from '../../../Assets/Result/icons/shea butter op1.png'
import Protein from '../../../Assets/Result/icons/protein op2.png'
import Water from '../../../Assets/Result/icons/hydration op1.png'
import Sun from '../../../Assets/Result/icons/sunscreen op1.png'

import Sleep from '../../../Assets/Result/icons/sleep op2.png'
import Hydration1 from '../../../Assets/Result/icons/hydration op2.png'
import Massage from '../../../Assets/Result/icons/firm healthy skin op1.png'
import Diet from '../../../Assets/Result/icons/diet rich vegetables and fruits op1.png'

const Hydration = () => {
  return (
    <ResultCategory  
      concernContent={
        <ul>
          <li>
            <p>Low Hydration, leading to skin that lacks moisture, appearing less supple and plump.</p>
          </li>
          <ResultImageRow imgLink={LackofFirmness} >
            Common Causes:
            <ul>
              <li>A compromised skin barrier, which leads to transepidermal water loss.</li>
              <li>Oily skin can appear hydrated but often lacks water content, causing the skin to overproduce oil to compensate.</li>
              <li>Frequent cleansing to remove excess sebum can strip the skin of its natural moisture.</li>
              <li>As we age, the natural moisturizing factors in the skin deplete, requiring additional hydration.</li>
            </ul>
          </ResultImageRow>
          <ResultImageRow imgLink={Anitoxidant} >
            What you need:
            <ul>
              <li>Skincare products containing Vitamin E, an antioxidant that also acts as a humectant to attract and retain moisture, helping your skin stay hydrated, soft, and plump.</li>
            </ul>
          </ResultImageRow>
        </ul>
      }
      solutionContent={
        <ul>
          <li>
            <p>ZENDERMIS SKIN MOISTURISING FACE CREAM is a unique formulation that is compatible for all skin types and helps to repair photodamage that is caused by prolonged UV damage. The formulation is enriched with Niacinamide that nourishes and fortiﬁes the skin to restore skin’s natural bounce and youthful feel of the skin. The formulation is also added with ceramides that creates a protective skin barrier against the environmental aggressors like pollution and harsh weather. The Glutathione and Kojic Acid within the formulation brightens and evens out skin tone and also reduces dark spots. The Low Molecular Weight Hyaluronic Acid penetrates into deep dermal layer of the skin to give optimum hydration and makes a protective barrier over skin.</p>
          </li>
          <li>
            <p>This lightweight yet deeply hydrating cream:</p>
          </li>
          <ResultImageRow imgLink={Extract} >
            The formulation is enriched with Niacinamide that nourishes and fortiﬁes the skin to restore skin’s natural bounce and youthful feel of the skin.
          </ResultImageRow>
          <ResultImageRow imgLink={Protein} >
            The formulation is also added with ceramides that creates a protective skin barrier against the environmental aggressors like pollution and harsh weather.
          </ResultImageRow>
          <ResultImageRow imgLink={Water} >
            The Glutathione and Kojic Acid within the formulation brightens and evens out skin tone and also reduces dark spots.
          </ResultImageRow>
          <ResultImageRow imgLink={Sun} >
            The Low Molecular Weight Hyaluronic Acid penetrates into deep dermal layer of the skin to give optimum hydration and makes a protective barrier over skin.
          </ResultImageRow>
        </ul>
      }
      expertTipContent={
        <ul>
          <li>
            <p>To Restore Moisture to Your Skin:</p>
          </li>
          <ResultImageRow imgLink={Diet} >
            Stay hydrated by drinking 1.5–2 liters of water daily.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration1} >
            Limit your intake of coffee and alcohol, as they can contribute to dehydration.
          </ResultImageRow>
          <ResultImageRow imgLink={Massage} >
            Follow a balanced diet that includes fruits and vegetables with high water content, such as cucumber, watermelon, zucchini, and gourds.
          </ResultImageRow>
          <ResultImageRow imgLink={Sleep} >
            Protect your skin from extreme temperature changes to prevent moisture loss.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration1} >
            Reduce stress by practicing relaxation techniques, such as meditation, to help maintain skin hydration.
          </ResultImageRow>
        </ul>
      }
    />
  )
}

export default Hydration