import React, { useState } from 'react'
import GuidePage from '../../GuidePage'
import { Button, Checkbox, FormControlLabel, IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import page3 from '../../../../Assets/Guide/3-2.png'

const GuidePage3 = ({setPage}) => {
  const [checked, isChecked] = useState(false)
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* Guide 3 */}
      <GuidePage imgPath={page3}>
          {/* <h1>Smart SkinAdvisor's AI Powered Technology</h1>
          <p>helps you understand your skin’s unique needs & shares personalized skin care recommendations for Healthy Skin!</p> */}
          <h1>Share Your Selfie</h1>
          {/* <img
          src="https://smartskinadvisor.dermafique.com/analysis/assets/newassets/smartdermaconsult.png"
          alt="title"
          /> */}
          <p>
          Upload your photo and our AI will analyze 7 key skin health parameters to provide personalized insights and recommendations.
          </p>
          <FormControlLabel 
            className='terms'
            control={<Checkbox />} 
            label={<p>I agree with your <span style={{textDecoration:'underline'}} onClick={handleClickOpen}>terms of privacy</span></p>} 
            sx={{
              width: '100%',
              justifyContent: 'center'
            }}
            checked={checked}
            onChange={() => isChecked(prev => !prev)}
          />


          <Button
            sx={{
              background: '#0d3d82',
              color: '#fff',
              borderRadius: '20px',
              padding: '8px 20px' 
            }}
            onClick={() => {
              if(checked !== true) {
                alert("Please accept the terms of privacy to proceed")
                return
              }
              setPage("question")
            }}
          >Let's start</Button>
          <Dialog
            open={open}
            onClose={handleClickClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll='paper'
            sx={{
              maxWidth:'450px',
              margin: 'auto'
            }}
          >
            <DialogTitle id="alert-dialog-title"
              sx={{
                padding: '10px 10px 0 0'
              }}
            >
              <IconButton onClick={handleClickClose}
                sx={{
                  float: 'right',
                  padding: '0'
                }}
              >
                <HighlightOffIcon 
                  sx={{
                    fontSize: '30px'
                  }}
                />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h4>Purpose and Scope</h4>
                <p>We, <strong>Modaviti e-Marketing Private Limited</strong> ("<strong>Orbo</strong>" or "<strong>we</strong>" or "<strong>our</strong>" or "<strong>us</strong>"), [having our registered office at 202, 2nd Floor, D wing, Times Square, Andheri Kurla Road, Marol Naka Andheri East,
                Mumbai-400059] are committed to protecting the privacy of the information provider ("you" /
                "your" / "yourself"). We consider privacy and protection of your data/ information to be of high
                importance. Our practices and procedures in relation to the collection and use of your data/
                information has been set-out below in this privacy policy ("<strong>Privacy Policy</strong>"). This Privacy Policy will
                familiarize you with the manner in which Orbo may collect, use, share, transfer and disclose your
                data/ information.</p>
                <p>Orbo reserves the right, at its discretion, to change, modify, amend, add or remove portions of this
                Privacy Policy at any time without notice. We recommend that you review this Privacy Policy
                periodically to ensure that you are aware of the current privacy practices of Orbo.</p>
                <p>Your use of and access to the services provided on the Microsite is subject to your acceptance of the
                Terms of Use, which also involves the acceptance of this Privacy Policy. If you do not agree with the
                provisions of the same, you should discontinue the use of the Microsite immediately. Orbo shall not
                be responsible in any manner whatsoever for any violation or misuse of Your personal information by
                unauthorised persons consequent to misuse of the internet environment.</p>
                <p>This Privacy Policy shall be construed to be provided in compliance with the Information Technology
                Act 2000, as amended from time to time ("IT Act"), and shall be read with the Information
                Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or
                Information) Rules 2011 ("SPDI Rules"), as applicable.</p>
                <h4>Definitions</h4>
                <p>In this Privacy Policy, unless the context otherwise requires:</p>
                <ol>
                <li>'Personal Information' means any information that relates to a natural person which, either
                directly or indirectly, in combination with other information available or likely to be available
                with Orbo, is capable of identifying such person.</li>
                <li>'Sensitive Personal Data or Information' of a person means such Personal Information which
                consists of information relating to:
                <ul style={{listStyleType: "disc"}}>
                <li>password;</li>
                <li>financial information such as bank account or credit card or debit card or other payment instrument details;</li>
                <li>physical, physiological and mental health condition;</li>
                <li>sexual orientation;</li>
                <li>medical records and history;</li>
                <li>biometric information;</li>
                <li>any detail relating to the above Clauses as provided to Orbo for providing service; and</li>
                <li>any of the information received under any of the above Clauses by Orbo for processing, stored or
                processed under lawful contract or otherwise:
                Provided that any information that is freely available or accessible in public domain or furnished
                under the Right to Information Act 2005 or any other law for the time being in force will not be
                regarded as Sensitive Personal Data or Information.</li></ul></li>
                <li>The words and expressions used in this Privacy Policy but not defined herein but defined in
                the IT Act or SPDI Rules will have the meanings assigned to them thereunder.</li>
                </ol>
                <h4>Types of information collected</h4>
                <ol>
                <li>Information shared during using the services provided on our Microsite: Information that
                you may provide to us, voluntarily, while registering on our Microsite/using the services
                provided on our Microsite. This includes the information that we collect from you such as
                information related to your name, age, e-mail ID, skin type, skin sensitivity, your facial
                photograph/ facial scans, etc.</li>
                <li>Information automatically collected: We may automatically receive and record on our server
                log details relating to your access or use of Microsite, including but not limited to, your
                location, information about your internet connection and mobile device, internet protocol
                address, cookie information, web beacon information and details of the web pages you visit,
                etc. Further, we may also collect information about your preferences and settings such as
                time zone and language, your searches and the results you selected from the search.</li>
                <li>You represent that the information or data you provide from time to time is and shall be
                correct, current and updated and you have all the rights, permissions and consents to
                provide such information or data. Your providing the data/ information and Orbo’s
                consequent storage, collection, usage, transfer, access or processing of the same shall not be
                in violation of any third -party agreement, laws, judgments, orders or decrees.</li>
                </ol>
                <h4>Collection, Use, Processing and Retention of data/ information</h4>
                <ol style={{listStyleType: "lower-alpha"}}>
                <li>Orbo uses the information only for the purpose for which the same has been furnished. Our
                primary purpose for collecting information (including Personal Information and/or Sensitive Personal
                Data or Information) from you is to allow you to use the Microsite and avail our services offered on
                the Microsite in a seamless and efficient manner, including:
                <ol>
                <li>to provide, maintain, develop, protect and improve the Microsite;</li>
                <li>to understand your preferences and to enhance and customize your experience of using the
                Microsite;</li>
                <li>to send you offers based on your interests and prior activity, to contact you as a survey
                respondent, to notify you if you win any contest; and to send you promotional materials
                from its contest sponsors or advertisers;</li>
                <li>to respond to your comments, reviews and questions and provide better service;</li>
                <li>to carry out Orbo’s obligations and enforce Orbo’s rights arising from any contracts entered
                into between you and Orbo;</li>
                <li>to communicate important notices or changes in the products or services provided by Orbo
                on the Microsite, use of the Microsite and the terms/policies which govern the relationship
                between you and Orbo;</li>
                <li>for internal purposes such as auditing, data analysis and research relating to the products
                purchased by you, either directly by Orbo</li>
                <li>to undertake necessary fraud and money laundering prevention checks;</li>
                <li>to comply with applicable laws, rules and regulations;</li>
                <li>for promotion and marketing purposes by Orbo</li>
                <li>for sharing such information with any third party, including any service providers and any
                associated entities;</li>
                <li>to provide, personalize, and improve the products;</li>
                <li>for any other legitimate business purposes.</li>
                </ol></li>
                <li>Your data/ information will be kept confidential to the maximum possible extent and in accordance
                with the provisions set out herein.</li>
                <li>We will retain your information for as long as needed to provide you the services. We shall not
                retain such information for longer than is required for the purposes for which the information may
                lawfully be used or is otherwise required under any other law for the time being in force. After a
                period of time, your information may be anonymized and aggregated, and then may be held by us as
                long as necessary for us to provide services effectively or is otherwise required under any other law
                for the time being in force.</li>
                </ol>
                <h4>Disclosure and transfer of your Personal Information (Including Sensitive Personal Data or Information)</h4>
                <ol style={{listStyleType: "lower-alpha"}}>
                <li>We may share/disclose/ transfer your Personal Information (including Sensitive Personal Data or
                Information) to P squared Healthcare, our partners, associates, service providers, dealers, sellers and
                third parties as necessary or appropriate:
                <ol>
                <li>to carry out services on Orbo’s behalf under contract;</li>
                <li>to provide you better services, personalize and improve products offered on the Microsite,
                for promotion and marketing purposes, etc;</li>
                <li>in any manner permitted under applicable law;</li>
                <li>to comply with any legal process whether local or foreign;</li>
                <li>to respond to requests from public and government authorities, including public and
                government authorities outside your country of residence;</li>
                <li>to enforce our Terms of Use and policies;</li>
                <li>when Orbo, in its sole discretion, deems it necessary in order to protect its rights or the
                rights of others;</li>
                <li>to protect our rights or property, privacy, safety or property, and/or that of our affiliates, you
                or others; and</li>
                <li>to allow us to pursue available remedies or limit the damages that we or others may sustain.</li>
                </ol>
                You expressly consent to the sharing of Your information with such partners, associates, service
                providers, dealers, sellers and third parties as necessary or appropriate.</li>
                <li>Orbo may also disclose or transfer your Personal Information (including Sensitive Personal Data or
                Information), to another third party as a part of reorganization or a sale of the assets or business of
                Orbo. Any third party to which Orbo transfers or sells its assets will have the right to continue to use
                such Personal Information (including Sensitive Personal Data or Information).</li>
                </ol>
                <h4>Information provider’s rights in relation to their Sensitive Personal Data or Information collected by Orbo</h4>
                <ol style={{listStyleType: "lower-alpha"}}>
                <li>All Sensitive Personal Data or Information provided to Orbo by you have been voluntarily provided
                to us. You have the right to withdraw your consent at any time in writing by sending an e-mail to us
                at contact@psquaredhealthcare.com with the details of your phone number/ email that was used to
                log-in to the Tool, in accordance with the terms of this Privacy Policy. However, please note that
                withdrawal of consent will not be retrospective in nature and shall only be applicable prospectively.
                In case you do not provide your information or consent for usage of Sensitive Personal Data or
                Information or subsequently withdraw your consent for usage of the Sensitive Personal Data or
                Information so collected, Orbo reserves the right to discontinue the services for which the said
                information was sought.</li>
                <li>You may write to us at contact@psquaredhealthcare.com with the details of your phone number/
                email that was used to log-in to the Tool, to access, review, modify or correct, remove or delete your
                Sensitive Personal Data or Information or withdraw your consent to provide Sensitive Personal Data
                or Information. However, we are not responsible for the authenticity of the Sensitive Personal Data
                or Information provided by you.</li>
                <li>You agree and acknowledge that certain data or information may not be corrected or is prohibited
                to be modified as required under any applicable law, law enforcement requests or under any judicial
                proceedings. In respect to such data or information, the aforementioned rights will not be available.</li>
                </ol>
                <h4>Security Practices and Procedures</h4>
                <ol style={{listStyleType: "lower-alpha"}}>
                <li>We use reasonable security measures, at the minimum those mandated under the IT Act, and read
                with SPDI Rules, to safeguard and protect your Sensitive Personal Data or Information. You accept
                the inherent security implications of providing privacy to your information shared with us and you
                will not hold us responsible for any breach of security or the disclosure of Personal Information
                unless we have been grossly and willfully negligent.</li>
                <li>We may enter into agreement(s) with third parties (in or outside of India) to store your
                information or data. These third parties may have their own security standards to safeguard your
                information or data and we will on commercial reasonable basis require such third parties to adopt
                reasonable security standards to safeguard your information / data.</li>
                <li>Notwithstanding anything contained in this Privacy Policy or elsewhere, we shall not be held
                responsible for any loss, damage or misuse of your data/ information, if such loss, damage or misuse
                is attributable to a Force Majeure Event. A "Force Majeure Event" shall mean any event that is
                beyond our reasonable control and shall include, without limitation, sabotage, fire, flood, explosion,
                acts of God, pandemic or any public health emergency, civil commotion, strikes or industrial action of
                any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to
                computer data and storage device, computer crashes, breach of security and encryption, etc.</li>
                </ol>
                <h4>Grievance Redressal</h4>
                <p>Any discrepancies and grievances with respect to processing of Sensitive Personal Data or Information or any complaints in relation to the products shall be informed to the Grievance Officer as mentioned below:</p>
                <a href="mailto:contact@psquaredhealthcare.com">contact@psquaredhealthcare.com</a>
                <p>+91 9767995031</p>
                <h4>Cookies</h4>
                <p>A cookie is a small file that may be placed on your computer's hard disk for record-keeping purposes. Cookies do not contain any information that can personally identify you. By using this Microsite, you agree to our use of cookies as described in this Privacy Policy. We use the following cookies:</p>
                <ul>
                <li><strong>Strictly necessary cookies:</strong> These cookies are needed for the Microsite to work. They include, for example, the cookies that keep track of whether a visitor is logged in to the
                Microsite.</li>
                <li><strong>Analytical/performance cookies:</strong> These let us recognise and count how many visitors we receive, and how they move around our Microsite. This helps us improve the Microsite, for example, so users can easily find what they are looking for.</li>
                <li><strong>Targeting cookies:</strong> These record your visit to our Microsite, the pages you have visited and the links you have followed. We use this information to make our Microsite and the advertising displayed on it more relevant to your interests. We may also share this
                information with third parties for this purpose.</li>
                <li><strong>Third party cookies:</strong> Third parties may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</li>
                </ul>
                <strong>By entering this Microsite and clicking the "I accept" button, the visitor consents to the terms of this Privacy Policy. By submitting his/her data/information to Orbo in this test on Orbo's servers, the visitor will be treated as having given his/her permission for processing the same in a manner provided in this Privacy Policy.</strong>
              </DialogContentText>
            </DialogContent>
          </Dialog>
      </GuidePage>
    </div>
  )
}

export default GuidePage3