import React from 'react'
import ResultCategory from '../ResultCategory'
import ResultImageRow from '../ResultImageRow/ResultImageRow'

import LackofFirmness from '../../../Assets/Result/icons/lack of firmness op2.png'
import Anitoxidant from '../../../Assets/Result/icons/anti - oxidant op2.png'

import Extract from '../../../Assets/Result/icons/shea butter op1.png'
import Protein from '../../../Assets/Result/icons/protein op2.png'
import Water from '../../../Assets/Result/icons/hydration op1.png'
import Sun from '../../../Assets/Result/icons/sunscreen op1.png'

import Sleep from '../../../Assets/Result/icons/sleep op2.png'
import Hydration from '../../../Assets/Result/icons/hydration op2.png'
import Massage from '../../../Assets/Result/icons/firm healthy skin op1.png'
import Diet from '../../../Assets/Result/icons/diet rich vegetables and fruits op1.png'

const Smoothness = () => {
  return (
    <ResultCategory  
      concernContent={
        <ul>
          <li>
            <p>Skin smoothness is affected by clogged pores and the buildup of dead skin cells, leading
            to a rough texture.</p>
          </li>
          <ResultImageRow imgLink={LackofFirmness} >
            Common Causes:
            <ul>
              <li>Clogged pores from accumulated micro-impurities due to insufficient cleansing.</li>
              <li>A buildup of dead skin cells, leaving the surface feeling rough.</li>
            </ul>
          </ResultImageRow>
          <ResultImageRow imgLink={Anitoxidant} >
            What you need:
            <ul>
              <li>A skincare regimen that deeply cleanses pores, gently exfoliates to remove dead skin cells, and restores the skin's natural pH balance.</li>
              <li>This prepares the skin for optimal hydration, helping to rejuvenate its smooth and supple texture.</li>
            </ul>
          </ResultImageRow>
        </ul>
      }
      solutionContent={
        <ul>
          <li>
            <p>Zendermis SKIN Clarifying FACE SERUM is a unique formula that works best against acne breakouts. The salicylic acid face serum helps to shed off the dead skin cells and unclogs pores to give a smooth skin texture and reduce dryness and scaliness. The Serum is added with low molecular weight Hyaluronic Acid to prevent dryness and scaliness. </p>
          </li>
          <li>
            <p>This lightweight yet deeply hydrating cream:</p>
          </li>
          <ResultImageRow imgLink={Extract} >
            Is infused with Sea Weed Extracts, that are known to contain vitamins & minerals which have hydrating & brightening properties.
          </ResultImageRow>
          <ResultImageRow imgLink={Protein} >
            Zendermis SKIN Clarifying FACE SERUM is a unique formula that works best against acne breakouts.
          </ResultImageRow>
          <ResultImageRow imgLink={Water} >
            The salicylic acid face serum helps to shed off the dead skin cells and unclogs pores to give a smooth skin texture and reduce dryness and scaliness.
          </ResultImageRow>
          <ResultImageRow imgLink={Sun} >
            The Serum is added with low molecular weight Hyaluronic Acid to prevent dryness and scaliness.
          </ResultImageRow>
        </ul>
      }
      expertTipContent={
        <ul>
          <li>
            <p>For Smooth and Healthy Skin:</p>
          </li>
          <ResultImageRow imgLink={Diet} >
            Incorporate green leafy vegetables into your diet, as they are rich in antioxidants that promote skin health.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration} >
            Engage in regular exercise and ensure you get at least 7 hours of restful sleep to support skin regeneration.
          </ResultImageRow>
          <ResultImageRow imgLink={Massage} >
            Use a broad-spectrum sunscreen daily to protect your skin from harmful UV rays and environmental stressors.
          </ResultImageRow>
          <ResultImageRow imgLink={Sleep} >
            Minimize exposure to pollution to help safeguard your skin from potential damage.
          </ResultImageRow>
        </ul>
      }
    />
  )
}

export default Smoothness