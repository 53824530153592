import React from 'react'
import ResultCategory from '../ResultCategory'
import ResultImageRow from '../ResultImageRow/ResultImageRow'

import LackofFirmness from '../../../Assets/Result/icons/lack of firmness op2.png'
import Anitoxidant from '../../../Assets/Result/icons/anti - oxidant op2.png'

import Extract from '../../../Assets/Result/icons/shea butter op1.png'
import Protein from '../../../Assets/Result/icons/protein op2.png'
import Water from '../../../Assets/Result/icons/hydration op1.png'
import Sun from '../../../Assets/Result/icons/sunscreen op1.png'

import Sleep from '../../../Assets/Result/icons/sleep op2.png'
import Hydration from '../../../Assets/Result/icons/hydration op2.png'
import Massage from '../../../Assets/Result/icons/firm healthy skin op1.png'
import Diet from '../../../Assets/Result/icons/diet rich vegetables and fruits op1.png'

const FineLines = () => {
  return (
    <ResultCategory  
      concernContent={
        <ul>
          <li>
            <p>Fine Lines and Wrinkles, which can begin to appear as early as your mid to late twenties, starting at a cellular level.</p>
          </li>
          <ResultImageRow imgLink={LackofFirmness} >
            Common Causes:
            <ul>
              <li>A slowdown in the cell regeneration process.</li>
              <li>Reduced collagen production and loss of skin elasticity.</li>
            </ul>
          </ResultImageRow>
          <ResultImageRow imgLink={Anitoxidant} >
            What you need:
            <ul>
              <li>Skincare products containing plant stem cell extracts to help regenerate skin cells and promote renewal.</li>
              <li>Formulations with anti-aging actives that stimulate skin cell turnover, helping to reduce the appearance of fine lines and wrinkles.</li>
              <li>To combat dryness, ensure your skincare contains humectants that help hydrate and plump the skin.</li>
            </ul>
          </ResultImageRow>
        </ul>
      }
      solutionContent={
        <ul>
          <li>
            <p>Vitamin C is the most potent form of  Ethyl Ascorbic Acid and the one that’s not only safe to use but also penetrates the skin from within to lighten the skin tone, giving it a more even-toned and healthy look. Ferulic Acid in the 10% Vitamin C Face Serum boosts Vitamin C’s antioxidant effects and helps protect overall skin integrity by reducing the development of fine lines, spots, and wrinkles. The HYALURONIC ACID in the serum Penetrates deep into the skin to infuse hydration in all conditions, making skin plumper, softer and smoother, while promoting skin cell regeneration.</p>
          </li>
          <li>
            <p>This lightweight yet deeply hydrating cream:</p>
          </li>
          <ResultImageRow imgLink={Extract} >
            Is infused with Sea Weed Extracts, that are known to contain vitamins & minerals which have hydrating & brightening properties.
          </ResultImageRow>
          <ResultImageRow imgLink={Protein} >
            Vitamin C is the most potent form of Ethyl Ascorbic Acid and the one that’s not only safe to use but also penetrates the skin from within to lighten the skin tone, giving it a more even-toned and healthy look.
          </ResultImageRow>
          <ResultImageRow imgLink={Water} >
            Ferulic Acid in the 10% Vitamin C Face Serum boosts Vitamin C’s antioxidant effects and helps protect overall skin integrity by reducing the development of fine lines, spots, and wrinkles.
          </ResultImageRow>
          <ResultImageRow imgLink={Sun} >
            The HYALURONIC ACID in the serum Penetrates deep into the skin to infuse hydration in all conditions, making skin plumper, softer and smoother, while promoting skin cell regeneration.
          </ResultImageRow>
        </ul>
      }
      expertTipContent={
        <ul>
          <li>
            <p>Fine lines and wrinkles are a natural part of aging, but you can help delay their appearance by:</p>
          </li>
          <ResultImageRow imgLink={Diet} >
            Staying well-hydrated to maintain skin elasticity.
          </ResultImageRow>
          <ResultImageRow imgLink={Hydration} >
            Eating a balanced diet rich in vitamins, minerals, and antioxidants to support skin health.
          </ResultImageRow>
          <ResultImageRow imgLink={Massage} >
            Reducing stress through practices like yoga and meditation, which may also contribute to slowing down the aging process.
          </ResultImageRow>
        </ul>
      }
    />
  )
}

export default FineLines