import React, { useEffect, useState } from 'react'
import QuestionPage from '../QuestionPage'
import { Box, Button } from '@mui/material'

const QuestionPage2 = ({ setData }) => {
    const [age, setAge] = useState(35)

    const handleDecrement = () => {
        if(age <= 18) {
            alert("Age cannot be less than 18 years")
            return
        }
        setAge(prevAge => prevAge - 1)
    }

    const handleIncrement = () => {
        setAge(prevAge => prevAge + 1)
    }

    const ageButtonStyle = {
        minWidth: '50px',
        width: '50px',
        height: '50px',
        background: '#0d3d82',
        color: '#fff',
        borderRadius: '25px',
        fontSize: '25px'
    }
    useEffect(() => {
        setData(prevData => {
            const newData = [...prevData]
            newData[1] = age
            return newData
        })
    }, [age])

  return (
    <QuestionPage
        title="How old are you?"
        description="Did you know the signs of ageing begin to show as early as the age of 18"

    >
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }}>
            <Button sx={ageButtonStyle} onClick={handleDecrement}>-</Button>
            <span style={{fontSize: '70px', width: '150px', color: '#0d3d82', textAlign: 'center' }}>{age}</span>
            <Button sx={ageButtonStyle} onClick={handleIncrement}>+</Button>
        </Box>
    </QuestionPage>
  )
}

export default QuestionPage2